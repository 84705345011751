<template>
<div>
  <vs-row vs-justify="center">
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card>
        <h2 class="card-title d-flex">{{$t('DEBITORS.HEADER.TITEL')}}
          <div class="ml-auto text-right">
            <vs-button  @click="LoadData()" size="small" color="primary" type="filled" icon="refresh"></vs-button>
          </div>
        </h2>
        <p class="card-subtitle">
          <span>{{$t('DEBITORS.HEADER.TEXT')}}</span>
        </p>
        <AddDebitorDlg ref="addDebitorDlg" v-on:Accepted="AddDebitorAccepted"></AddDebitorDlg>
        <AddDebitorObjectDlg ref="addDebitorObjectDlg" v-on:Accepted="AddDebitorObjectAccepted" ></AddDebitorObjectDlg>
      </vs-card>
    </vs-col>
    <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
      <vs-card >
        <vs-tabs v-model="activeTab">
          <vs-tab :label="$t('DEBITORCONTACT.DEBITORS')" >
            <div class="d-flex align-items-center">
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="$refs.addDebitorDlg.showDlg()" >{{$t('DEBITORS.ADD')}}</vs-button>
            </div> 
            <div id="debitors_table" class="vs-con-loading__container" >
              <vs-table
                search
                :data="debitors"
                :noDataText="$t('DEBITORS.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORS.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="debitor.lastname">
                    {{$t('DEBITORS.TABLE.COL1')}}
                  </vs-th>
                  <vs-th sort-key="debitor.debitor_nr">
                    {{$t('DEBITORS.TABLE.COL6')}}
                  </vs-th>                  
                  <vs-th sort-key="debitor.email">
                    {{$t('DEBITORS.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="debitor.tel1">
                    {{$t('DEBITORS.TABLE.COL3')}}
                  </vs-th>
                  <vs-th sort-key="rech_adr.street1">
                    {{$t('DEBITORS.TABLE.COL4')}}
                  </vs-th>        
                  <vs-th sort-key="lief_adr.street1">
                    {{$t('DEBITORS.TABLE.COL5')}}
                  </vs-th>    
                  <vs-th sort-key="debitor.registered">
                    {{$t('DEBITORS.TABLE.COL7')}}
                  </vs-th>                   
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="data[indextr].debitor.lastname">
                      {{GetFirmaFromData(data[indextr].debitor)}}<br v-if="data[indextr].debitor.company.length > 0" />
                      {{GetNameFromData(data[indextr].debitor)}}
                    </vs-td>  
                    <vs-td :data="data[indextr].debitor.debitor_nr">
                      {{data[indextr].debitor.debitor_nr}}
                    </vs-td>                     
                    <vs-td :data="data[indextr].debitor.email">
                      {{data[indextr].debitor.email}}<span v-if="IsNotNull(data[indextr].debitor.email2)"><br/>{{data[indextr].debitor.email2}}</span>
                    </vs-td> 
                    <vs-td :data="data[indextr].debitor.tel1">
                      {{$t('DEBITORS.TABLE.TEL1')}}{{data[indextr].debitor.tel1}}<br/>
                      {{$t('DEBITORS.TABLE.TEL2')}}{{IsTextEmpty(data[indextr].debitor.tel2) == false ? data[indextr].debitor.tel2 : '-'}}
                    </vs-td>                    
                    <vs-td :data="data[indextr].rech_adr">
                      {{data[indextr].rech_adr.street1}}<br v-if="IsTextEmpty(data[indextr].rech_adr.street2) == false"/>
                      {{IsTextEmpty(data[indextr].rech_adr.street2) == false ? data[indextr].rech_adr.street2 : '' }}<br/>
                      {{data[indextr].rech_adr.plz + ' ' + data[indextr].rech_adr.city}}<br/>
                      {{GetNameOfCountry(data[indextr].rech_adr.country_fk)}}
                    </vs-td>   
                    <vs-td :data="data[indextr].lief_adr">
                      <template v-if="typeof(data[indextr].lief_adr) != 'undefined' && data[indextr].lief_adr != null && data[indextr].lief_adr.street1 != null">
                      {{data[indextr].lief_adr.street1}}<br v-if="IsTextEmpty(data[indextr].lief_adr.street2) == false"/>
                      {{IsTextEmpty(data[indextr].lief_adr.street2) == false ? data[indextr].lief_adr.street2 : '' }}<br/>
                      {{data[indextr].lief_adr.plz + ' ' + data[indextr].lief_adr.city}}<br/>
                      {{GetNameOfCountry(data[indextr].lief_adr.country_fk)}}
                      </template>
                      <template v-else>
                        {{$t('DEBITORS.TABLE.LIKE_RECH_ADR')}}
                      </template>
                    </vs-td>
                    <vs-td :data="data[indextr].debitor.registered">
                      <vs-chip > {{DateToString(data[indextr].debitor.registered)}} </vs-chip>
                      <vs-chip v-show="data[indextr].debitor.fromInfoworx" color="lightblue">infoworx</vs-chip>
                    </vs-td>                         
                    <vs-td>
                      <ButtonList :buttonArray="[
                                       {label:$t('DEBITORS.BTN.EDIT'),color:'primary',type:'filled',icon:'edit',show:true,disabled:false,data:[data[indextr]]},
                                       {label:$t('DEBITORS.BTN.TO_OBJECT'),color:'primary',type:'filled',icon:'house',show:true,disabled:false,data:[data[indextr].debitor.id]},
                                       {label:$t('DEBITORS.BTN.DELETE'),color:'primary',type:'filled',icon:'delete',show:true,disabled:false,data:[data[indextr].debitor.id,GetNameFromData(data[indextr].debitor)]},
                                       {label:$t('DEBITORS.BTN.ANONYM'),color:'primary',type:'filled',icon:'delete',show:true,disabled:false,data:[data[indextr].debitor.id,GetNameFromData(data[indextr].debitor)]},
                                       {label:$t('DEBITORS.BTN.EXPORT'),color:'warning',type:'filled',icon:'file_download',show:true,disabled:false,data:[data[indextr].debitor.id,GetNameFromData(data[indextr].debitor)]},
                                      ]" v-on:BtnClicked="ButtonListClicked"></ButtonList>
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div>    
          </vs-tab>
          <vs-tab :label="$t('DEBITORCONTACT.DEBITOR_OBJECTS')" :disabled="actDebitor <= 0">
            <div class="d-flex align-items-center" >
              <vs-button class="mt-3 mb-2 mr-2"  type="filled" icon="arrow_back_ios" @click="activeTab = 0" >{{$t('DEBITORS.BACK_OVERVIEW')}}</vs-button>
              <vs-button class="mt-3 mb-2"  type="filled" icon="add" @click="$refs.addDebitorObjectDlg.showDlg(actDebitor)" >{{$t('DEBITORS.OBJ.ADD')}}</vs-button>
            </div> 
            <div id="debitors_obj_table123" class="vs-con-loading__container" >
              <vs-table
                search
                :data="debitorobjects"
                :noDataText="$t('DEBITORS.OBJ.NO_DATA')"
                stripe
                max-items="25" 
                pagination
                hoverFlat>

                <template slot="header">
                  <h3>
                    {{$t('DEBITORS.OBJ.TABLE.HEADER')}}
                  </h3>
                </template>
                <template slot="thead">             
                  <vs-th sort-key="debitorobject.name">
                    {{$t('DEBITORS.OBJ.TABLE.COL1')}}
                  </vs-th>             
                  <vs-th sort-key="debitorobject.description">
                    {{$t('DEBITORS.OBJ.TABLE.COL2')}}
                  </vs-th>
                  <vs-th sort-key="adress1.street1">
                    {{$t('DEBITORS.OBJ.TABLE.COL3')}}
                  </vs-th>        
                  <vs-th sort-key="adress2.street1">
                    {{$t('DEBITORS.OBJ.TABLE.COL4')}}
                  </vs-th>    
                  <vs-th></vs-th>                                                                                                                                             
                </template>

                <template slot-scope="{data}">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data" >                    
                    <vs-td :data="data[indextr].debitorobject.name">
                      {{data[indextr].debitorobject.name}}
                    </vs-td>                     
                    <vs-td :data="data[indextr].debitorobject.description">
                      {{data[indextr].debitorobject.description.substring(0,50)}}
                    </vs-td>                    
                    <vs-td :data="data[indextr].address1">
                      {{data[indextr].address1.street1}}<br v-if="IsTextEmpty(data[indextr].address1.street2) == false"/>
                      {{IsTextEmpty(data[indextr].address1.street2) == false ? data[indextr].address1.street2 : '' }}<br/>
                      {{data[indextr].address1.plz + ' ' + data[indextr].address1.city}}<br/>
                      {{GetNameOfCountry(data[indextr].address1.country_fk)}}
                    </vs-td>   
                    <vs-td :data="data[indextr].address2">
                      <template v-if="typeof(data[indextr].address2) != 'undefined' && data[indextr].address2 != null && data[indextr].address2.street1 != null">
                      {{data[indextr].address2.street1}}<br v-if="IsTextEmpty(data[indextr].address1.street2) == false"/>
                      {{IsTextEmpty(data[indextr].address2.street2) == false ? data[indextr].address2.street2 : '' }}<br/>
                      {{data[indextr].address2.plz + ' ' + data[indextr].address2.city}}<br/>
                      {{GetNameOfCountry(data[indextr].address2.country_fk)}}
                      </template>
                      <template v-else>
                        {{$t('DEBITORS.OBJ.TABLE.LIKE_RECH_ADR')}}
                      </template>
                    </vs-td>                         
                    <vs-td>
                      <vs-button @click="$refs.addDebitorObjectDlg.InitDlgData(data[indextr])" class="ml-2" size="small" color="primary" type="filled" icon="edit"></vs-button>
                      <vs-button @click="OpenConfirmDeleteObject(data[indextr].debitorobject.id,data[indextr].debitorobject.name)" class="ml-2" size="small" color="primary" type="filled" icon="delete"></vs-button>                        
                    </vs-td>                    
                                                                                                                                                                                              
                  </vs-tr>
                </template>
              </vs-table>
            </div> 
          </vs-tab>
          </vs-tabs>       
        </vs-card>
      </vs-col>
  </vs-row>

</div>
</template>

<script>

import helperMethods from '../helper/staticFuncHelper';
import AddDebitorDlg from './components/dialogs/AddDebitorDlg';
import AddDebitorObjectDlg from './components/dialogs/AddDebitorObjectDlg';
import codeHelper from '../helper/staticCodeHelper';
import ButtonList from './components/ButtonList.vue';
import {documentType} from '../helper/enumHelper';

export default {
  name: "Debitors",
  components: {
    AddDebitorDlg,
    AddDebitorObjectDlg,
    ButtonList
  },
    data: function (){
      return {
      debitors:[],
      debitorobjects:[],
      actDebitor:0,
      activeTab:0
      };
  },
  created () {
      this.LoadData();
    },
  computed: 
  { 
    getTableData() {
      var data = [];
      if(this.$store.state.debitor.debitors.data != null)
      {
        data = this.$store.state.debitor.debitors.data;
      }
      return data;
    },
    getTableDataDebitorObj() {
      var data = [];
      if(this.$store.state.debitorobject.debitorobjects.data != null)
      {
        data = this.$store.state.debitorobject.debitorobjects.data;
      }
      return data;
    },    
    loadingTableData () {
      var loading = false;
      if(typeof(this.$store.state.debitor.debitors.status) != 'undefined')
        loading = this.$store.state.debitor.debitors.status.loading;
      return loading;
    },    
    loadingTableDataDebitorObj () {
      var loading = false;
      if(typeof(this.$store.state.debitorobject.debitorobjects.status) != 'undefined')
        loading = this.$store.state.debitorobject.debitorobjects.status.loading;
      return loading;
    }

  },
  watch: {
      getTableData(value) {
          this.debitors = value;
      },
      getTableDataDebitorObj(value){
         this.debitorobjects = value;
      },
      activeTab(value){
        if(value == 0)
        {
          this.actDebitor = 0;
        }
      },
      loadingTableData(value)
      {

        // Wirft einen fehler. Vermutlich weil die table in einem Tab sitzt und der Load somit nicht angezeigt werden kann...

        if(value)
        {
          //this.$store.dispatch('alertqueue/showLoader', {'id':'#debitors_table'});
        }
        else
        {
          //this.$store.dispatch('alertqueue/hideLoader', {'id':'#debitors_table'});
        }
      },
      loadingTableDataDebitorObj(value)
      {
        if(value)
        {
          //this.$store.dispatch('alertqueue/showLoader', {'id':'#debitors_obj_table123'});
        }
        else
        {
          //this.$store.dispatch('alertqueue/hideLoader', {'id':'#debitors_obj_table123'});
        }
      }
    },
  methods: {    
      ...helperMethods,
      ...codeHelper,
      LoadData: function ()
      {
        this.$store.dispatch('debitor/getMyDebitors',{ root: true },{ root: true }); 
        // Kundendropdownliste aktualisieren
        this.$store.dispatch('debitor/getMyDebitorsMin', { root: true },{ root: true }); 
      },
      AddDebitorAccepted(){
        this.LoadData();
      },
      AddDebitorObjectAccepted(id){
        this.ShowDebitorObjects(id);
      },
      ShowDebitorObjects(id){
        this.actDebitor = id;
        this.activeTab = 2;
        this.$store.dispatch('debitorobject/getDebitorObjects',id);
      },
      OpenConfirmDelete(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DEBITORS.QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      OpenConfirmDeleteObject(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.DELETE'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DEBITORS.OBJ.QUESTION.DELETE').replace("*1*",bez),
          accept: this.SendDeleteObjRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      OpenConfirmAnonym(id,bez) {
        this.$vs.dialog({
          type: "confirm",
          color: "primary",
          title: this.$t('COM.SURE.TITEL'),
          acceptText: this.$t('COM.ANONYM'),
          cancelText:this.$t('COM.CANCEL'),
          text: this.$t('DEBITORS.QUESTION.ANONYM').replace("*1*",bez),
          accept: this.SendAnonymRequest,
          parameters:{"id": id,"bez": bez}
        });
      
      },
      SendDeleteRequest: function(parameters)
      {
        this.$store.dispatch('debitor/deleteDebitor', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORS.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
          }
          else
          {
            var text = this.$t('DEBITORS.WARNING.DELETE').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORS.ERROR.DELETE').replace("*1*",parameters.bez) + ' ' +error});  
        });
      },
      SendDeleteObjRequest: function(parameters)
      {
        this.$store.dispatch('debitorobject/deleteDebitorObject', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.$store.dispatch('debitorobject/getDebitorObjects',this.actDebitor);
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORS.OBJ.SUCCESS.DELETE').replace("*1*",parameters.bez)});  
          }
          else
          {
            var text = this.$t('DEBITORS.OBJ.WARNING.DELETE').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORS.OBJ.ERROR.DELETE').replace("*1*",parameters.bez) + ' ' +error});  
        });
      },
      SendAnonymRequest: function(parameters)
      {
        this.$store.dispatch('debitor/anonymDebitor', parameters.id)
        .then(response => {
          if(response.success === true)
          {
            this.LoadData();
            this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORS.SUCCESS.ANONYM').replace("*1*",parameters.bez)});  
          }
          else
          {
            var text = this.$t('DEBITORS.WARNING.ANONYM').replace("*1*",parameters.bez);
            text = text.replace("*2*",this.$t(response.text));
            this.$store.dispatch('alertqueue/addWarning', {'titel':this.$t('COM.WARNING.TITEL'),'text':text}); 
          }
        }, 
        error =>{

            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORS.ERROR.ANONYM').replace("*1*",parameters.bez) + ' ' +error});  
        });
      },
      CreateZip(id,name){

        this.$store.dispatch('document/createDocument', {id:id,name:name, type: documentType.DEBITORDATAALL})
          .then(success => {
            if(success === true)
            {
              this.$store.dispatch('alertqueue/addSuccess', {'titel':this.$t('COM.SUCCESS.TITEL'),'text':this.$t('DEBITORS.SUCCESS.EXPORT').replace("*1*",name)});
            }
            else
            {
              this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORS.ERROR.EXPORT').replace("*1*",name)});
            }
          }, 
          error =>{
            this.$store.dispatch('alertqueue/addError', {'titel':this.$t('COM.ERROR.TITEL'),'text':this.$t('DEBITORS.ERROR.EXPORT').replace("*1*",name)+' '+error});
          }); 
      },

      ButtonListClicked(index, data){
        switch(index)
        {
          case 0:
            this.$refs.addDebitorDlg.InitDlgData(data[0]);
            break;
          case 1:
            this.ShowDebitorObjects(data[0]);
            break;
          case 2:
            this.OpenConfirmDelete(data[0],data[1]);
            break; 
          case 3:
            this.OpenConfirmAnonym(data[0],data[1]);
            break;  
          case 4:
            this.CreateZip(data[0],data[1]);
            break;                           
        }
        
      }
  }
};

</script>